import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import FmsSectorModule from '../../fmsSector';
export default {
  name: 'FmsPocketDetails',
  components: {
    ModelSelect,
    FmsSectorModule
  },
  data() {
    return {
      showSelectedPocketRecord: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      showFmsSectorList: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      updateMode: false,
      crmPocket: {
        value: null,
        text: null
      },
      crmPocketData: [
        {
          value: null,
          text: null
        }
      ],
      fmsSector: {
        value: null,
        text: null
      },
      fmsPocketName: null,
      fmsPocketCode: null,
      fmsPocketId: 0,
      defaultValue: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    this.eventBus.$off('showRecordDetails');
    this.eventBus.$on('showRecordDetails', recordDetails => {
      this.fmsPocketCode = recordDetails.fms_pocket_code;
      this.fmsPocketId = recordDetails.fms_pocket_id;
      this.fmsPocketName = recordDetails.fms_pocket_name;
      this.crmPocket = {
        value: recordDetails.crm_pocket_id,
        text: recordDetails.crm_pocket_name
      };
      this.fmsSector = {
        value: recordDetails.fms_sector_id,
        text: recordDetails.fms_sector_name
      };
    });
    this.eventBus.$off('addCase');
    this.eventBus.$on('addCase', editMode => {
      this.updateMode = editMode;
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addAndEditPocket();
        }
        if (actionName === 'update') {
          this.updateMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.fmsPocketId });
        }
      }
    });
  },
  methods: {
    openSectorList() {
      this.showFmsSectorList = true;
    },
    selectFmsSector(sectorData) {
      this.fmsSector = {
        value: sectorData.fms_sector_id,
        text: sectorData.fms_sector_name
      };
      this.showFmsSectorList = false;
    },
    hideFmsSectorList() {
      this.showFmsSectorList = false;
    },
    addAndEditPocket() {
      const payload = {
        fms_pocket_id: this.fmsPocketId,
        crm_pocket_id: this.crmPocket.value,
        fms_pocket_code: this.fmsPocketCode,
        fms_pocket_name: this.fmsPocketName,
        fms_sector_id: this.fmsSector.value
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/addAndEditPocket', payload)
        .then(response => {
          this.$emit('updateList');
          this.updateMode = false;
          if (response.status === 200) {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.CRM_POCKET) {
        this.crmPocket = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.CRM_POCKET) {
        this.parent_value_set_id = this.fmsSector.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.fmsSector.value) {
        this.fmsSector = this.defaultValue;
      } else if (vsetCode === this.crmPocket.value) {
        this.crmPocket = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
