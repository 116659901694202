import commonHelper from '@/app/utility/common.helper.utility';
import FmsPocketDetails from './pocketDetails';
import { ModelSelect } from 'vue-search-select';
import MasterProjectModule from '../fmsMasterProject';
import FmsSectorModule from '../fmsSector';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'FmsPocket',
  components: {
    ModelSelect,
    FmsPocketDetails,
    MasterProjectModule,
    FmsSectorModule
  },
  props: [
    'showFmsPocketList',
    'fmsSectorName',
    'fmsSectorId',
    'fmsMasterProjName'
  ],
  watch: {
    currentPage: function() {
      this.getFmsPocketList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsPocketList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      showSelectedPocketRecord: false,
      showMasterProjectModule: false,
      showFmsSectorList: false,
      loader: false,
      editMode: false,
      payload: {},
      updateMode: false,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      fmsMasterProj: {
        value: null,
        text: null
      },
      fmsMasterProjData: [
        {
          value: null,
          text: null
        }
      ],
      fmsSector: {
        value: null,
        text: null
      },
      fmsSectorList: [
        {
          value: null,
          text: null
        }
      ],
      fmsPocketName: '',
      fmsPocketCode: '',
      fmsPocketData: [],
      fmsPocketFields: [
        {
          key: 'fms_sector_name',
          label: 'Sector'
        },
        {
          key: 'fms_pocket_name',
          label: 'Pocket'
        },
        {
          key: 'fms_pocket_code',
          label: 'Pocket Code'
        },
        {
          key: 'crm_pocket_name',
          label: 'CRM Pocket'
        }
      ],
      defaultValue: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    if (this.showFmsPocketList) {
      this.fmsMasterProj.text = this.fmsMasterProjName;
      this.fmsSector.text = this.fmsSectorName;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showSelectedPocketRecord = true;
          this.timeoutEdit = setTimeout(() => {
            this.eventBus.$emit('addCase', !this.editmode);
          }, 0);
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'unitInventory/getFmsPocketList',
            'fms-pocket',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    openMasterProjectList() {
      this.showMasterProjectModule = true;
    },
    selectMasterProjectList(data) {
      this.fmsMasterProj = {
        value: data.fms_master_prj_id,
        text: data.fms_master_prj_name
      };
      this.fmsSector = {
        value: null,
        text: null
      };
      this.showMasterProjectModule = false;
    },
    hideMasterProjectModule() {
      this.showMasterProjectModule = false;
    },
    openSectorList() {
      if (!this.fmsMasterProj.text) {
        return alert(appStrings.DEPENDENTVSETMSG.FMSMASTERPRJMSG);
      }
      this.showFmsSectorList = true;
    },
    selectFmsSector(sectorData) {
      this.fmsSector = {
        value: sectorData.fms_sector_id,
        text: sectorData.fms_sector_name
      };
      this.showFmsSectorList = false;
    },
    hideFmsSectorList() {
      this.showFmsSectorList = false;
    },
    getFmsPocketList() {
      this.payload = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        fms_sector_id: this.fmsSectorId
          ? this.fmsSectorId
          : this.fmsSector.value,
        fms_pocket_code: this.fmsPocketCode,
        fms_pocket_name: this.fmsPocketName
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/getFmsPocketList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.fmsPocketData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(rowData) {
      if (this.$router.currentRoute.name === 'fmsPocket') {
        this.showSelectedPocketRecord = true;
        setTimeout(() => {
          this.eventBus.$emit('showRecordDetails', rowData);
        }, 0);
      } else {
        this.$emit('selectedFmsPocket', rowData);
      }
    },
    updateList() {
      this.getFmsPocketList();
    },
    clearFilters() {
      this.fmsMasterProj = this.defaultValue;
      this.fmsSector = this.defaultValue;
      this.fmsPocketName = '';
      this.fmsPocketCode = '';
      this.fmsPocketData = [];
      this.totalRows = null;
    },
    hidePocketRecord() {
      this.showSelectedPocketRecord = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.fmsMasterProj.text) {
        this.fmsMasterProj = this.defaultValue;
      } else if (vsetCode === this.fmsSector.text) {
        this.fmsSector = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
